<template>
  <tbody class="divide-y divide-bb-neutral-50">
    <tr
      v-for="(item, index) in data"
      :key="index"
      class="h-18 text-sm hover:bg-bb-background-purple-0 cursor-pointer"
      @click="$emit('select', item)"
    >
      <td class="px-4">
        <p class="flex items-center gap-x-2"><ic-avatar :size="30" /> {{ item.active ? item.name : '-' }}</p>
      </td>

      <td class="px-4">
        {{ item.email }}
      </td>

      <td class="px-4">
        {{ item.role.name }}
      </td>

      <td class="text-left px-4">
        <p>{{ item.organizations.length }}</p>
      </td>

      <td class="text-left px-4">
        <p v-if="item.active">
          {{ dateToString(new Date(item.last_login_at)) }}
        </p>

        <p
          v-else
          class="italic"
        >
          Pending invite
        </p>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { dateToString } from '@/utils/date'
import IcAvatar from '@/components/icon/ic-avatar'

export default {
  name: 'UserManagementRows',
  components: {
    IcAvatar,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    dateToString,
  },
}
</script>
